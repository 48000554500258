export default {
    data() {
        return {
            isLoadingModelList: true,
            search: '',
            searchTimeout: -1,
            count: 0,
            models: [],
            page: 1,
            activeModel: { id: -1, description: "Models are Loading" }
        }
    },
    computed: {
        pages() {
            return Math.ceil(this.count / 10);
        },
    },
    watch: {
        search(data) {
            if (this.searchTimeout !== -1) clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.GET_INITIAL_MODEL_LIST(1, 10, data)
            }, 1000);
        },
    },
    methods: {
        SET_PAGINATION(page) {
            this.GET_INITIAL_MODEL_LIST(page, 10, this.search);
            this.page = page;
            this.$router.replace({ query: { page: page } })
        },
        async GET_INITIAL_MODEL_LIST(page = 1, limit = 10, search = '') {
            this.page = page;
            this.isLoadingModelList = true;
            const response = await this.$api.get(
                `Products?brandId=${this.$route.params.brandId}&offset=${page}&limit=${limit}&searchKey=${search}`
            );
            if (response.result === "OK" && response.message === "OK") {
                this.models = response.data.items;
                this.count = response.data.count;
                if (this.$route.params.brandId) {
                    this.activeModel = response.data.items.find(d => d.id === Number(this.$route.params.brandId))
                }
            }
            else this.models = [];
            this.isLoadingModelList = false;
        },
    },
    mounted() {
        let page = 1;
        if(this.$route.query.page) page = Number(this.$route.query.page)
        this.GET_INITIAL_MODEL_LIST(page);
    }
}