<template>
  <div>
    <Models :key="keyComp" />
  </div>
</template>

<script>
import Models from "../../components/models/index.vue";
export default {
  components: {
    Models,
  },
  data(){
    return {
      keyComp: 1
    }
  },
  watch: {
    '$route'(){
      this.keyComp ++;
    },
  }
};
</script>

<style>
</style>