<template>
  <div>
    <div class="mb-4 d-flex justify-content-end">
      <button class="btn btn-primary d-flex" @click="showNewModelsModal = true">
        <i class="material-icons-outlined">add</i>
        <span class="ml-2"> New Model</span>
      </button>
    </div>
    <div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <label for="">Search Model Number</label>
          <div class="form-group position-relative">
            <input
              class="form-control"
              v-model="search"
              required
              type="text"
              aria-describedby="emailHelp"
              placeholder="Enter Model Number"
            />
                        <i
              v-if="search !== ''"
              @click="search = ''"
              title="Clear of items"
              class="ml-2 material-icons-outlined text-primary close-input"
              >close</i
            >
          </div>
        </div>
      </div>
      <card-table-header :headers="fields"></card-table-header>
      <cardTableBody
        :allItemsCount="models.length"
        :items="models"
        :page="1"
        :pages="1"
        :loading="isLoadingModelList"
      >
        <div
          class="
            card
            row
            p-3
            d-flex
            flex-row
            justify-content-sm-end justify-content-xl-start
          "
          v-for="(item, i) in models"
          :key="i"
        >
          <div class="col-2 col-lg-1">{{ item.id }}</div>
          <div class="col-7 col-lg-4 text-primary">
            <router-link :to="`/models/${item.id}`">
              <div class="mx-2">{{ item.model }}</div>
            </router-link>
          </div>
          <div class="col-3 col-lg-2">
            <span
              :class="`btn btn-xs btn-block btn-${
                item.isActive === 1 ? 'primary' : 'danger'
              }`"
              >{{ item.isActive === 1 ? "On" : "Off" }}</span
            >
          </div>
          <div class="col-12 col-lg-5 mt-4 mt-lg-0">
            <button
              class="btn btn-warning mx-2 btn-xs"
              @click="
                editModel = { ...item, index: i };
                showModal = true;
              "
            >
              <i style="font-size: 14px" class="material-icons-outlined mr-2"
                >border_color</i
              >Update
            </button>
          </div>
        </div>
      </cardTableBody>
      <div class="d-flex justify-content-end align-items-center">
        <eralp-pagination
          :page="page"
          :pages="pages"
          @change="(e) => SET_PAGINATION(e, true)"
        />
      </div>
    </div>
    <modal
      okText="Save"
      title="New Model"
      v-model="showNewModelsModal"
      @ok="SEND_NEW_MODEL_TO_API"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="newModel.model"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Model Name"
        />
      </div>
    </modal>
    <modal
      @ok="SEND_UPDATE_MODEL_TO_API"
      okText="Save Changes"
      title="Update Model"
      v-model="showModal"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="editModel.model"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Model Name"
        />
      </div>
      <div class="mt-4">
        <strong>Situation: </strong>
        <button
          @click="editModel.isActive = 1"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editModel.isActive === 0,
            'btn-primary': editModel.isActive === 1,
          }"
        >
          Active
        </button>
        <button
          @click="editModel.isActive = 0"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editModel.isActive === 0,
            'btn-danger': editModel.isActive === 0,
          }"
        >
          Archived
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Getmodels from "./getModels";
import cardTableHeader from "../ui/table/cardTableHeader.vue";
import cardTableBody from "../ui/table/cardTableBody.vue";
import Modal from "../ui/modal.vue";
export default {
  mixins: [Getmodels],
  data() {
    return {
      alert: {
        status: false,
        variant: "success",
        message: "",
      },
      newModel: { model: "", isActive: 1 },
      showNewModelsModal: false,
      showModal: false,
      editModel: {},
      fields: [
        { label: "Id", sort: false, col: 12, md: 6, lg: 1, xl: 1 },
        { label: "Name", sort: false, col: 12, md: 6, lg: 4, xl: 4 },
        { label: "Situation", sort: false, col: 12, md: 6, lg: 2, xl: 2 },
        { label: "Actions", sort: false, col: 12, md: 6, lg: 5, xl: 5 },
      ],
    };
  },
  components: {
    Modal,
    cardTableHeader,
    cardTableBody,
  },
  methods: {
    async SEND_NEW_MODEL_TO_API(e, loading) {
      this.alert.status = false;
      if (this.newModel.model === "") return;
      loading();
      const response = await this.$api.post("Products", {
        model: this.newModel.model,
        isActive: 1,
        brandId: this.$route.params.brandId,
      });
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Model is updated successfully",
        };
        this.GET_INITIAL_MODEL_LIST();
        setTimeout(() => {
          this.showNewModelsModal = false;
          this.alert.status = false;
        }, 1000);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
    async SEND_UPDATE_MODEL_TO_API(e, loading) {
      this.alert.status = false;
      if (this.editModel.model === "") return;
      loading();
      const response = await this.$api.put(`Products/${this.editModel.id}`, {
        model: this.editModel.model,
        isActive: this.editModel.isActive,
        brandId: this.$route.params.brandId,
      });
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Model saved successfully",
        };
        this.GET_INITIAL_MODEL_LIST();
        setTimeout(() => {
          this.showModal = false;
          this.alert.status = false;
        }, 1000);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
  },
};
</script>

<style>
</style>